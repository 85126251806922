































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Props, User, Schedules, Global } from '@/store';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import PropEditor from '@/components/PropEditor.vue';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import cloneDeep from 'lodash/cloneDeep';
import { Types } from '@/modules/API';

@Component({
  components: {ConfirmDialog, PropEditor, draggable, MessageDialog}
})
export default class ScheduleManagement extends Vue {
  @User.State('project') project;
  @Props.State('list') properties;
  @Schedules.Action('loadSchedules') loadSchedules;
  @Schedules.State('schedules') schedules;
  @Schedules.Mutation('schedules') setSchedules;
  @Schedules.Action('runSchedulerInServer') runSchedulerInServer;
  @Global.State('lang') lang;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Global.State('timezone') projectTimezone;
  @Global.State('readonly_user') readonly_user;

  isMessageDialogOpen = false;
  message = 'There is no timezone for this project';
  info = 'Please contact with operation team';
  currentHeaderClass = '';
  isLoading = false;
  group = null;
  deleteDialog = false;
  schedule_to_delete = null;
  has_changes = false;
  saveDialog = false;
  schedules_copy = [];
  route_to = null;
  saveMessage = '';

  mounted() {
    this.setPageTitle('Schedule Management');
    this.load();
  }

  updated() {
    this.currentHeaderClass = this.lang === 'en' ? 'text-xs-left' : 'text-xs-right';
  }

  handleSaveClicked(){
    this.saveDialog = true;
    this.saveMessage = 'Are you sure you want to save changes?';
  }

  handleSaveCancled(){
    this.saveDialog = false;
    if (this.route_to) {
      this.has_changes = false;
      this.$router.push(this.route_to.fullPath);
    }
  }

  goToSchedule(){
    this.projectTimezone
      ? this.$router.push('/schedules/create')
      : this.isMessageDialogOpen = true;
  }

  handleCheckbox(item){
    this.has_changes = true;
    const schedule = this.schedules_copy.find((schedule) => schedule.id === item.id);
    schedule.active = item.active ? 0 : 1;
  }

  handleChange(){
    this.has_changes = true;
    this.schedules_copy.forEach((schedule, index) => schedule.priority = index + 1);
  }

  updateScheduleProperty(){
    const schedule_property = this.properties.find((item) => item.name === 'dashboard.schedules');
    if (schedule_property.value) {
      schedule_property.value = this.schedules_copy;
      this.setSchedules(schedule_property);
    }
  }

  @Watch('schedules') 
  handleSchedules(){
    this.schedules_copy = cloneDeep(this.schedules);
  }

  @Watch('project')
  async load() {
    if (this.project){
      this.isLoading = true;
      await this.loadSchedules({projectId: this.project.id});
      this.isLoading = false;
    }
  }

  removeSchedule() {
    this.has_changes = true;
    this.schedules_copy = this.schedules_copy.filter((schedule) => schedule.id !== this.schedule_to_delete);
    this.schedules_copy.forEach((schedule, index) => schedule.priority = index + 1);
    this.schedule_to_delete = null;
    this.deleteDialog = false;
  }

  openDeleteDialog(schedule_id) {
    this.deleteDialog = true;
    this.schedule_to_delete = schedule_id;
  }

  async saveChanges(){
    (this.$refs.propEditor as PropEditor).save(this.schedules_copy, {
      name: 'dashboard.schedules',
      objectID: this.project.id,
      objectType: Types.PROJECTS
    }, () => {
      this.saveDialog = false;
      this.$notify({
        text: 'Schedules Updated Successfully',
        type: 'success',
        title: 'Success'
      });
      this.runSchedulerInServer();
      this.has_changes = false;
      if (this.route_to) this.$router.push(this.route_to.fullPath);
    });
  }

  beforeRouteLeave(to, from, next) {
    this.route_to = to;

    if (this.has_changes) {
      this.saveMessage = 'Save changes before exiting?';
      this.saveDialog = true;
    }else next();
  }
}
